<template>
<div>
  <!--<link rel="stylesheet" href="https://unpkg.com/@coreui/icons/css/all.min.css">-->
        <loading :active.sync="loading"></loading>
        <div class="row"><div class="col-md-12">
          <b-card style="max-width: 1050px;">
            <div slot="header">
              <div class="row">
            <div class="col-md-6">
              <strong>Insumos em {{ tipoProdutoSimples }}s - ID: {{ id }} </strong>
            </div>
            <div class="col-md-6" style="text-align:right;">
              <b-button variant="success" size="sm" style="color:white;" @click="mop()">Editar</b-button>
              &nbsp;&nbsp;
                <b-button variant="link" size="sm" @click="ajuda()"><CIcon name="cil-lightbulb" />&nbsp;<small>Ajuda</small></b-button>
            </div>
            </div>
          </div>

        <table class="table table-hover">
            <thead>
            <tr>
                <th colspan="3">
                    <h2>{{ p.prd_name }}</h2>
                    <small><em v-html="tipoProduto"></em></small>
                    <div>
                    <div style="float:left; font-size: 14px; margin-right:5px;">Receita para fabricar</div>
                    <div style="float:left"><b-form-input type="number" class="verde pequeno centro" @blur.native="atualizaUnidadeVenda()" size="sm" v-model="unidadeVenda"></b-form-input></div>
                    <div style="float:left; font-size: 14px; margin-left:5px;"> {{ p.prd_descr }} de {{ p.prd_name }}.</div>
                    </div>
                </th>
                <th colspan="3">
                    <div style="text-align: right;">Total de insumos por {{ p.prd_descr }}:</div>
                    <h2 style="text-align: right; color: darkgreen;">{{ this.$session.get('regras')[0].grp_moeda }} {{ ( produto_total / unidadeVenda ) | grana }}</h2>
                    <div v-if="unidadeVenda > 1" style="text-align: right; font-size: 14px;">Total: <strong>{{ this.$session.get('regras')[0].grp_moeda }} {{ ( produto_total ) | grana }}</strong></div>
                </th>
            </tr>
            <tr>
                <th style="width:3%;"></th>
                <th style="width:45%;">Insumo</th>
                <th style="width:12%;">UnP</th>
                <th style="width:10%;">Qtde</th>
                <th style="width:10%;">Valor Unitário</th>
                <th style="text-align:right;width:20%;">Subtotais</th>
            </tr>
            </thead>

            <!-- MATERIAIS -->
            <tbody>
                <tr><td colspan="5" style="background-color:lightgrey;"><strong>MATERIAIS</strong></td><td style="background-color:lightgrey;"><div align="right">
                
              </div></td></tr>
                <tr v-for="(materialItem, index) in mat1" :key="index">
                    <td data-title="Ativar" v-if="mat1[index].mat_unc !== 'Fixo'">
                      <b-form-checkbox @change.native="editar(mat1[index].pmt_ID, 1, index)"
                        v-model="mat1[index].pmt_active" 
                      ></b-form-checkbox>
                    </td>
                    <td data-title="Insumo" v-if="mat1[index].mat_unc !== 'Fixo'" v-html="(mat1[index].mat_prdID ? '<abbr title=\'Preparo\'>' + optionsMateriaisList[mat1[index].pmt_mat_ID] + '</abbr>' : optionsMateriaisList[mat1[index].pmt_mat_ID])">
                    
                    </td>
                    
                    <td data-title="UnP" v-if="mat1[index].mat_unc !== 'Fixo'" v-html="(mat1[index].mat_prdID ? optionsPreparosUnP[mat1[index].mat_prdID] : optionsMateriaisUnP[mat1[index].pmt_mat_ID])">

                    </td>
                    
                    <td data-title="Qtde" v-if="mat1[index].mat_unc !== 'Fixo'">
                      <b-form-input style="text-align:center;" class="verde" type="number" @blur.native="editar(mat1[index].pmt_ID, 1, index)" v-model="mat1[index].pmt_qtde"></b-form-input>
                    </td>
                    
                    <td data-title="Valor Unitário" v-if="mat1[index].mat_unc !== 'Fixo'">
                      <vue-autonumeric class="form-control money amarelo" readonly v-model="mat_total[mat1[index].pmt_mat_ID]" :options="{ decimalCharacter: ',', digitGroupSeparator: '.', currencySymbol: (mat1[index].mat_moeda == 'BRL' ? 'R$' : mat1[index].mat_moeda) + ' ', currencySymbolPlacement: 'p', decimalPlaces: 3 }"></vue-autonumeric>
                    </td>
                    
                    <td data-title="Subtotal" v-if="mat1[index].mat_unc !== 'Fixo'" style="text-align:right;">
                      <vue-autonumeric class="form-control money amarelo" readonly v-model="mat_total_parcial[mat1[index].pmt_mat_ID]" :options="dinheiro2"></vue-autonumeric>
                    </td>
                </tr>
            </tbody>

            <!-- EMBALAGENS -->
            <tbody>
                <tr><td colspan="5" style="background-color:lightgrey;"><strong>EMBALAGENS</strong></td><td style="background-color:lightgrey;"><div align="right">
                
              </div></td></tr>
                <tr v-for="(materialItem, index) in mat2" :key="index">
                    <td data-title="Ativar">
                      <b-form-checkbox @change.native="editar(mat2[index].pmt_ID, 2, index)"
                        v-model="mat2[index].pmt_active" 
                      ></b-form-checkbox>
                    </td>
                    <td data-title="Insumo" v-if="mat2[index].mat_unc !== 'Fixo'" v-html="(mat2[index].mat_prdID ? '<abbr title=\'Preparo\'>' + optionsMateriaisList[mat2[index].pmt_mat_ID] + '</abbr>' : optionsMateriaisList[mat2[index].pmt_mat_ID])">
                    
                    </td>
                    <td data-title="UnP" v-html="optionsMateriaisUnP[mat2[index].pmt_mat_ID]">

                    </td>
                    <td data-title="Qtde">
                      <b-form-input style="text-align:center;" class="verde" type="number" @blur.native="editar(mat2[index].pmt_ID, 2, index)" v-model="mat2[index].pmt_qtde"></b-form-input>
                    </td>
                    <td data-title="Valor Unitário">
                      <vue-autonumeric class="form-control money amarelo" readonly v-model="mat_total[mat2[index].pmt_mat_ID]" :options="{ decimalCharacter: ',', digitGroupSeparator: '.', currencySymbol: (mat2[index].mat_moeda == 'BRL' ? 'R$' : mat2[index].mat_moeda) + ' ', currencySymbolPlacement: 'p', decimalPlaces: 3 }"></vue-autonumeric>
                    </td>
                    <td data-title="Subtotal" style="text-align:right;">
                      <vue-autonumeric class="form-control money amarelo" readonly v-model="mat_total_parcial[mat2[index].pmt_mat_ID]" :options="dinheiro2"></vue-autonumeric>
                    </td>
                </tr>
            </tbody>

            <!-- SERVIÇOS DE TERCEIROS -->
            <tbody>
                <tr><td colspan="5" style="background-color:lightgrey;"><strong>SERVIÇOS DE TERCEIROS</strong></td><td style="background-color:lightgrey;"><div align="right">
                
              </div></td></tr>
                <tr v-for="(materialItem, index) in mat3" :key="index">
                    <td data-title="Ativar">
                      <b-form-checkbox @change.native="editar(mat3[index].pmt_ID, 3, index)"
                        v-model="mat3[index].pmt_active" 
                      ></b-form-checkbox>
                    </td>
                    <td data-title="Insumo" v-if="mat3[index].mat_unc !== 'Fixo'" v-html="(mat3[index].mat_prdID ? '<abbr title=\'Preparo\'>' + optionsMateriaisList[mat3[index].pmt_mat_ID] + '</abbr>' : optionsMateriaisList[mat3[index].pmt_mat_ID])">
                    
                    </td>
                    <td data-title="UnP" v-html="optionsMateriaisUnP[mat3[index].pmt_mat_ID]">

                    </td>
                    <td data-title="Qtde">
                      <b-form-input style="text-align:center;" class="verde" type="number" @blur.native="editar(mat3[index].pmt_ID, 3, index)" v-model="mat3[index].pmt_qtde"></b-form-input>
                    </td>
                    <td data-title="Valor Unitário">
                      <vue-autonumeric class="form-control money amarelo" readonly v-model="mat_total[mat3[index].pmt_mat_ID]" :options="{ decimalCharacter: ',', digitGroupSeparator: '.', currencySymbol: (mat3[index].mat_moeda == 'BRL' ? 'R$' : mat3[index].mat_moeda) + ' ', currencySymbolPlacement: 'p', decimalPlaces: 3 }"></vue-autonumeric>
                    </td>
                    <td data-title="Subtotal" style="text-align:right;">
                      <vue-autonumeric class="form-control money amarelo" readonly v-model="mat_total_parcial[mat3[index].pmt_mat_ID]" :options="dinheiro2"></vue-autonumeric>
                    </td>
                </tr>
            </tbody>

            <!-- EQUIPAMENTOS -->
            <tbody>
                <tr><td colspan="5" style="background-color:lightgrey;"><strong>EQUIPAMENTOS</strong></td><td style="background-color:lightgrey;"><div align="right">
                
              </div></td></tr>
                <tr v-for="(materialItem, index) in mat4" :key="index">
                    <td data-title="Ativar">
                      <b-form-checkbox @change.native="editar(mat4[index].pmt_ID, 4, index)"
                        v-model="mat4[index].pmt_active" 
                      ></b-form-checkbox>
                    </td>
                    <td data-title="Insumo" v-if="mat4[index].mat_unc !== 'Fixo'" v-html="(mat4[index].mat_prdID ? '<abbr title=\'Preparo\'>' + optionsMateriaisList[mat4[index].pmt_mat_ID] + '</abbr>' : optionsMateriaisList[mat4[index].pmt_mat_ID])">
                                      
                    </td>
                    <td data-title="UnP">
                       {{ mat4[index].mat_unc }}
                    </td>
                    <td data-title="Qtde">
                      <b-form-input style="text-align:center;" class="verde" type="number" @blur.native="editar(mat4[index].pmt_ID, 4, index)" v-model="mat4[index].pmt_qtde"></b-form-input>
                    </td>
                    <td data-title="Valor Unitário">
                      <vue-autonumeric class="form-control money amarelo" readonly v-model="mat_total[mat4[index].pmt_mat_ID]" :options="{ decimalCharacter: ',', digitGroupSeparator: '.', currencySymbol: (mat4[index].mat_moeda == 'BRL' ? 'R$' : mat4[index].mat_moeda) + ' ', currencySymbolPlacement: 'p', decimalPlaces: 3 }"></vue-autonumeric>
                    </td>
                    <td data-title="Subtotal" style="text-align:right;">
                      <vue-autonumeric class="form-control money amarelo" readonly v-model="mat_total_parcial[mat4[index].pmt_mat_ID]" :options="dinheiro2"></vue-autonumeric>
                    </td>
                </tr>
                
            </tbody>

            <!-- INSUMOS EVENTUAIS -->
            <tbody v-for="(materialItem, index) in mat1" :key="index">
                <tr v-if="mat1[index].mat_unc === 'Fixo'"><td colspan="5" style="background-color:lightgrey;"><strong>INSUMOS EVENTUAIS</strong></td>
                <td style="background-color:lightgrey;text-align:right;"></td></tr>
                <tr v-if="mat1[index].mat_unc === 'Fixo'">
                    <td data-title="" v-if="mat1[index].mat_unc === 'Fixo'">
                      
                    </td>
                    <td data-title="" v-if="mat1[index].mat_unc === 'Fixo'">
                      <strong>Total dos insumos eventuais</strong>
                    </td>
                    
                    <td v-if="mat1[index].mat_unc === 'Fixo'"></td>

                    <td v-if="mat1[index].mat_unc === 'Fixo'"></td>

                    <td v-if="mat1[index].mat_unc === 'Fixo'">
                      
                    </td>

                    <td data-title="Subtotal" v-if="mat1[index].mat_unc === 'Fixo'" style="text-align:right;">
                      <vue-autonumeric class="form-control money amarelo" readonly v-model="mat_total_parcial[mat1[index].pmt_mat_ID]" :options="dinheiro2"></vue-autonumeric>
                    </td>
                </tr>
            </tbody>
        </table>
          </b-card>
          <b-modal size="lg" ref="modal-simples" hide-footer title="Insumos Eventuais">
            <b-alert variant="info" show dismissible>
              <p>Esta é uma opção de entrada rápida de insumos. Basta digitar itens e seus valores ou apenas o valor total dos insumos 
              e eles serão incorporados ao custo com insumos. Isso pode ser mais prático em precificações de produtos eventuais.</p>
              <p>O ponto fraco é que não haverá uma memória de cálculo detalhada e nem correção de custos quando ocorrer uma atualização de valores nos Cadastros.</p>
            </b-alert>
            <b-alert variant="danger" v-if="isMobile()" show>
        <p><strong>Desculpe!</strong> Este recurso no momento é incompatível com dispositivos móveis.</p>
      </b-alert>
            <hot-table ref="dataTables" :data="dataTable" :settings="dataTableSet"></hot-table>
            <div class="row">
              <div class="col-md-8">
                <b-button variant="success" @click="salvaSimplesData()">Salvar planilha</b-button>
                &nbsp;&nbsp;<b-button variant="danger" size="sm" @click="removeSimplesData()">Limpar</b-button>
              </div>
              <div class="col-md-4">
                <vue-autonumeric class="form-control money amarelo" :options="dinheiro" readonly v-model="totalDataTable"></vue-autonumeric>
              </div>
            </div>
          </b-modal>
          <b-modal size="lg" id="ajuda" hide-footer title="Uso de Insumos">
      <div>
      <b-card no-body>
        <b-tabs card>
          <b-tab no-body title="Tutorial" active>
            <b-card-text>
              <b-embed
              type="iframe"
              aspect="16by9"
              src="https://www.youtube.com/embed/OIuJ5wPC0zs?t=83&rel=0"
              allowfullscreen
            ></b-embed>
            </b-card-text>
          </b-tab>
        </b-tabs>
      </b-card>
      </div>
    </b-modal>
        </div></div>
    </div>
</template>

<script>
import Loading from 'vue-loading-overlay'
import VueAutonumeric from 'vue-autonumeric'
import { HotTable } from '@handsontable/vue'
var vm
export default {
  props: {
    id: {
      required: true
    }
  },
  data () {
    return {
      optionsMateriais: [],
      optionsMateriais1: [],
      optionsMateriais2: [],
      optionsMateriais3: [],
      optionsMateriais4: [],
      optionsMateriaisList: [],
      optionsMateriaisUnP: [],
      optionsPreparosUnP: [],
      unidadeVenda: 1,
      produtoItem: [],
      materialItem: [],
      sessao: '',
      loading: false,
      mat: [],
      mat1: [],
      mat2: [],
      mat3: [],
      mat4: [],
      p: [],
      totalDataTable: 0,
      prdName: '',
      prdCode: '',
      tipoProduto: '',
      tipoProdutoSimples: '',
      prdValor: 0,
      prdActive: 1,
      dinheiro: {
        decimalCharacter: ',',
        digitGroupSeparator: '.',
        currencySymbol: this.$session.get('regras')[0].grp_moeda + ' ',
        currencySymbolPlacement: 'p',
        decimalPlaces: 2
      },
      dinheiro2: {
        decimalCharacter: ',',
        digitGroupSeparator: '.',
        currencySymbol: this.$session.get('regras')[0].grp_moeda + ' ',
        currencySymbolPlacement: 'p',
        decimalPlaces: 3
      },
      percentual: {
        decimalCharacter: ',',
        digitGroupSeparator: '.',
        currencySymbol: ' %',
        currencySymbolPlacement: 's',
        decimalPlaces: 2
      },
      decimal: {
        decimalCharacter: ',',
        digitGroupSeparator: '.',
        currencySymbol: '',
        decimalPlaces: 2
      },
      novoMaterial: [],
      produto: 0,
      produto_total: 0,
      matdiv: [],
      matunc: [],
      mat_total: [],
      mat_total_parcial: [],
      produto_original: 0,
      dataTable: [],
      pmt_fixo: 0,
      dataTableSet: {
        colHeaders: ['Item', this.$session.get('regras')[0].grp_moeda + ' / Un', 'Qt', 'Subtotais'],
        rowHeaders: true,
        minSpareRows: 2,
        width: 700,
        height: 250,
        colWidths: [200, 150, 50, 200],
        columns: [
          {}, {
              type: 'numeric',
              numericFormat: {
                culture: 'pt-BR',
                pattern: '0,0.00'
              }
            },
            {
              type: 'numeric'
            },
            {
              type: 'numeric',
              numericFormat: {
                culture: 'pt-BR',
                pattern: '0,0.00'
              },
              readOnly: true
            }
        ],
        licenseKey: 'non-commercial-and-evaluation',
        afterChange: (changes) => {
          this.calcSimplesData()
        }
      }
    }
  },
  beforeCreate: function () {
    if (!this.$session.exists()) {
      this.$router.push('/login')
    }
  },
  created: function () {
    this.produto = this.id
    this.verificaSessao()
  },
  filters: {
    grana: function (num) {
      if (typeof num !== 'undefined') {
        return String(parseFloat(num).toFixed(2)).replace('.', ',').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
      }
    },
    somarArray: function (value) {
      var ret = 0
      for (var i = 0; i < value.length; i++) {
        ret = ret + parseFloat(value[i])
      }
      return ret
    },
    capitalize: function (s) {
      if (typeof s !== 'string') return ''
      return s.charAt(0).toUpperCase() + s.slice(1)
    }
  },
  methods: {
    isMobile: function () {
      if (screen.width <= 760) {
        return true;
      }
      else {
        return false;
      }
    },
    verificaSessao: function () {
      if (!this.$session.exists()) { this.$session.start() }
      this.$http.get(this.URLApi + 'user/session', {
        headers: {
          'X-DreamFactory-Session-Token': this.$session.get('jwt'),
          'X-Dreamfactory-API-Key': this.ChaveApi
        }
      }).then((response) => {
        if(isNaN(this.produto) || this.produto === 0) {
          this.$notify({
            type: 'danger',
            title: 'Falha ao encontrar produto',
            text: 'Inválido ou não encontrado'
          })
          return false
        }
        this.recarregar()
      }, (response) => {
        var m = response.body.error.code
        if (m === 401) {
          this.$notify({
            type: 'danger',
            title: 'Sessão expirada',
            text: 'Seu tempo de logado expirou.'
          })
        }
        this.$router.push('/login')
      })
    },
    recarregaResto: function (force) {
      // Carrega a lista de materiais associados
      this.mat = []
      this.mat1 = []
      this.mat2 = []
      this.mat3 = []
      this.mat4 = []
      // Verifica se tem o insumo eventual
      this.campo('filtro_Produto_Material', 'pmt_ID', '(prd_ID = ' + this.produto + ') AND (pmt_active = 1) AND (mat_unc LIKE Fixo)').then(
        (r) => {
          if(r > 0) {
            // Existe (faz nada)
            this.recarregaResto2(force)
          } else {
            // Não existe (cria)
            this.campo('pro_Material', 'mat_ID', '(mat_active = 1) AND (mat_unc LIKE Fixo)').then(
              (c) => {
                this.inserir('pro_Produto_Material', {resource: [ {pmt_mat_ID: c, prd_ID: this.produto, pmt_qtde: 0, pmt_active: 1} ]}, '', '1').then(
                  (resp) => {
                    this.recarregaResto2(force)
                  },
                  (resp) => {
                    this.recarregaResto2(force)
                  }
                )
              },
              (c) => {
                this.recarregaResto2(force)
              }
            )
          }
        },
        (r) => {
          // Não existe (cria)
          this.campo('pro_Material', 'mat_ID', '(mat_active = 1) AND (mat_unc LIKE Fixo)').then(
            (c) => {
              this.inserir('pro_Produto_Material', {resource: [ {pmt_mat_ID: c, prd_ID: this.produto, pmt_qtde: 0, pmt_active: 1} ]}, '', '1').then(
                (resp) => {
                  this.recarregaResto2(force)
                },
                (resp) => {
                  this.recarregaResto2(force)
                }
              )
            },
            (c) => {
              this.recarregaResto2(force)
            }
          )
        }
      )
    },
    mop: function () {
      this.$router.push('/tabelas/produtosmateriais/' + this.produto)
    },
    recarregaResto2: function (force) {
      var usoParams = {
        g: this.$session.get('grupo'),
        o: 'mat_name',
        w: '(prd_ID = ' + this.produto + ') AND (pmt_active = 1) AND ((pmt_qtde > 0) OR (mat_unc LIKE "Fixo"))'
      }
      this.$http.post(this.URLApp + 'tabelas/filtroProdutoMaterial', usoParams).then(
      // this.listar('filtro_Produto_Material', 'pmt_ID', '(prd_ID = ' + this.produto + ') AND (pmt_active = 1) AND ((pmt_qtde > 0) OR (mat_unc LIKE Fixo))').then(
        (r2) => {
          r2 = r2.body
          this.mat = r2
          this.mat1 = []
          this.mat2 = []
          this.mat3 = []
          this.mat4 = []
          this.produto_total = 0
          var fixoFix = 0
          for (var k = 0; k < r2.length; k++) {
            this.mat_total_parcial[r2[k].pmt_mat_ID] = r2[k].mat_valor_final * (r2[k].prd_material ? r2[k].prd_material : 1) // (r2[k].pmt_qtde * this.mat_total[r2[k].pmt_mat_ID])
            this.produto_total += this.mat_total_parcial[r2[k].pmt_mat_ID]
            if (r2[k].mat_unc === 'Fixo') {
              if (fixoFix === 0) {
                this.pmt_fixo = r2[k].pmt_ID
              }
              fixoFix++
              if (fixoFix > 1) {
                this.remover('pro_Produto_Material', 'pmt_ID = ' + r2[k].pmt_ID)
                this.recarregaResto2(force)
                return false
              }
            }
            // Dividindo por grupo
            if (r2[k].mtp_ID === 1) {
              this.mat1.push(r2[k])
            }
            if (r2[k].mtp_ID === 2) {
              this.mat2.push(r2[k])
            }
            if (r2[k].mtp_ID === 3) {
              this.mat3.push(r2[k])
            }
            if (r2[k].mtp_ID === 4) {
              this.mat4.push(r2[k])
            }
          }
          this.carregaSimplesData()
          this.loading = false
          if (force) {
            this.recarregar(false)
          }
        },
        (r2) => {
          this.$notify({
            type: 'danger',
            title: 'Dados insuficientes',
            text: 'Materiais não encontrados'
          })
          this.loading = false
          return false
        }
      )
    },
    recarregar: function (parcial) {
      this.loading = true
      if (parcial) {
        this.recarregaResto(true)
        return false;
      }
      this.listar('pro_Produto', 'prd_ID', 'prd_tipo = 2').then(
        (tmp) => {
          var preparoTipos = []
          if (tmp.length > 0) {
            for(var x = 0; x < tmp.length; x++) {
              preparoTipos[tmp[x].prd_ID] = tmp[x].prd_descr
            }
          }
          this.listar('pro_Material_Unidade', 'mun_ID', 'mun_active >= 0').then(
            (r) => {
              if (r.length === 0) {
                this.$swal('Nenhuma unidade de compra foi cadastrada', 'Você precisa criar unidades de compras antes de acessar essa tela. Acesse o menu CONFIGURAÇÕES > UNIDADES E CONVERSORES', 'error')
                this.loading = false
                return false
              }
              this.matdiv[1] = 1
              this.matunc[1] = 'unidade'
              for(var i = 0; i < r.length; i++) {
                this.matdiv[r[i].mun_ID] = r[i].mun_conv
                this.matunc[r[i].mun_ID] = r[i].mun_name
              }
              this.listar('pro_Material', 'mat_name', '(mat_active = 1)').then(
                (rtmp) => {
                  if (rtmp.length === 0) {
                    this.$swal('Nenhum insumo foi cadastrado', 'Você precisa cadastrar insumos antes de acessar essa tela. Acesse o menu TABELAS e preencha tudo o que for necessário.', 'error')
                    this.loading = false
                    return false
                  }
                  if (!this.produto) {
                    return false
                  }
                  this.listar('pro_Produto', 'prd_code, prd_name', '(prd_active = 1) AND (prd_ID = ' + this.produto + ')').then(
                    (r) => {
                      if (!r) {
                        this.$notify({
                          type: 'danger',
                          title: 'Dados insuficientes',
                          text: 'Produto não encontrado'
                        })
                        this.loading = false
                        return false
                      }
                      this.p = r[0]
                      this.tipoProduto = 'Produto'
                      if (this.p.prd_tipo == 2) {
                        this.tipoProduto = 'Preparo'
                      }
                      this.tipoProdutoSimples = this.tipoProduto.toLowerCase()
                      if (this.p.prd_descr) {
                        this.tipoProduto += '&nbsp;&nbsp;(' + this.p.prd_descr + ')'
                      }
                      this.produto_original = this.p.prd_valor
                      this.unidadeVenda = this.p.prd_material
                      if (this.unidadeVenda === null) {
                        this.unidadeVenda = 1
                      }
                      // Prepara lista de materiais
                      var opt1 = []
                      var opt2 = []
                      var opt3 = []
                      var opt4 = []
                      var optall = []
                      var tmpval = 0
                      for (var k = 0; k < rtmp.length; k++) {
                        if (rtmp[k].mat_tipo_ID === 1) {
                          if (rtmp[k].mat_unc !== 'Fixo') {
                            if (this.p.prd_name !== rtmp[k].mat_name) {
                              if (rtmp[k].prd_ID > 0) {
                                opt1.push({ label: 'PREPARO: ' + rtmp[k].mat_name + ' (' + (typeof this.matunc[rtmp[k].mat_unidade_ID] === 'undefined' ? this.p.prd_descr : this.matunc[rtmp[k].mat_unidade_ID]) + ')', value: rtmp[k].mat_ID })
                              } else {
                                opt1.push({ label: 'MATERIAL: ' + rtmp[k].mat_name + ' (' + (typeof this.matunc[rtmp[k].mat_unidade_ID] === 'undefined' ? this.p.prd_descr : this.matunc[rtmp[k].mat_unidade_ID]) + ')', value: rtmp[k].mat_ID })
                              }
                            }
                          }
                        }
                        if (rtmp[k].mat_tipo_ID === 2) {
                          opt2.push({ label: 'EMBALAGEM: ' + rtmp[k].mat_name + ' (' + this.matunc[rtmp[k].mat_unidade_ID] + ')', value: rtmp[k].mat_ID })
                        }
                        if (rtmp[k].mat_tipo_ID === 3) {
                          opt3.push({ label: 'SERVIÇO DE TERCEIRO: ' + rtmp[k].mat_name + ' (' + this.matunc[rtmp[k].mat_unidade_ID] + ')', value: rtmp[k].mat_ID })
                        }
                        if (rtmp[k].mat_tipo_ID === 4) {
                          opt4.push({ label: 'EQUIPAMENTO: ' + rtmp[k].mat_name + ' (' + rtmp[k].mat_unc + ')', value: rtmp[k].mat_ID })
                        }
                        
                        this.optionsMateriaisList[(rtmp[k].mat_ID === null ? 1 : rtmp[k].mat_ID)] = '<strong>' + rtmp[k].mat_name + '</strong>'
                        this.optionsMateriaisUnP[(rtmp[k].mat_ID === null ? 1 : rtmp[k].mat_ID)] = '' + this.matunc[(rtmp[k].mat_unidade_ID === null ? 1 : rtmp[k].mat_unidade_ID)] + ''
                        if (rtmp[k].prd_ID > 0) {
                          this.optionsPreparosUnP[rtmp[k].prd_ID] = preparoTipos[rtmp[k].prd_ID]
                        }
                        // console.log(rtmp[k])
                        // console.log('--->' + this.matdiv[rtmp[k].mat_unidade_ID])
                        tmpval = parseFloat(rtmp[k].mat_value / (typeof this.matdiv[rtmp[k].mat_unidade_ID] === 'undefined' ? 1 : this.matdiv[rtmp[k].mat_unidade_ID]))
                        // console.log(tmpval)
                        if (rtmp[k].mat_tipo_ID === 4) {
                          tmpval = parseFloat(rtmp[k].mat_total)
                        }
                        this.mat_total[(rtmp[k].mat_ID === null ? 1 : rtmp[k].mat_ID)] = tmpval
                      }
                      this.optionsMateriais1 = opt1
                      this.optionsMateriais2 = opt2
                      this.optionsMateriais3 = opt3
                      this.optionsMateriais4 = opt4
                      optall = opt1.concat(opt2,opt3,opt4)
                      this.optionsMateriais = optall
                      this.recarregaResto()
                    },
                    (r) => {
                      this.$notify({
                        type: 'danger',
                        title: 'Dados insuficientes',
                        text: 'Produto inválido'
                      })
                      this.loading = false
                    }
                  )
                },
                (rtmp) => {
                  this.$notify({
                    type: 'danger',
                    title: 'Dados insuficientes',
                    text: 'Preencha as tabelas anteriores antes de acessar esta área.'
                  })
                  this.loading = false
                }
              )
            },
            (r) => {
              this.$notify({
                type: 'danger',
                title: 'Dados básicos necessários',
                text: 'Cadastre unidades de compra antes.'
              })
              this.loading = false
            }
          )
        },
        (tmp) => {
          this.$notify({
            type: 'danger',
            title: 'Erro desconhecido',
            text: 'Falha ao buscar preparos.'
          })
          this.loading = false
        }
      )
    },
    atualizaUnidadeVenda: function () {
      if (this.unidadeVenda <= 0 || this.unidadeVenda === null || typeof this.unidadeVenda === 'undefined') {
        this.unidadeVenda = 1
      }
      this.atualizar('pro_Produto', {resource: [ {prd_material: this.unidadeVenda} ]}, 'prd_ID = ' + this.produto, null, '1').then(
        (response) => {
          console.log(response)
        },
        (response) => {
          this.$swal('Erro ao atualizar unidade de venda')
        }
      )
    },
    inserirItem: function () {
      this.loading = true
      // Vazio não
      if (!this.novoMaterial.value) {
        this.$swal('Escolha um item antes')
        this.loading = false
        return false
      }
      // Verifica se já existe
      this.campo('pro_Produto_Material', 'pmt_ID', '(pmt_mat_ID = ' + this.novoMaterial.value + ') AND (prd_ID = ' + this.produto + ')').then(
        (response) => {
          // Se existe, apenas atualiza (reativa)
          this.atualizar('pro_Produto_Material', {resource: [ {pmt_active: 1} ]}, 'pmt_ID = ' + response).then(
            (response) => {
              this.$refs['modal-add'].hide()
              this.$swal('Este insumo já foi inserido')
              this.novoMaterial = []
              this.recarregar(true)
            },
            (response) => {
              this.$swal('Erro ao atualizar: ' + JSON.stringify(response))
              this.novoMaterial = []
              this.loading = false
            }
          )
          this.loading = false
          return false
        }, 
        (response) => {
          // Verifica se não é inserção de pré-preparo dentro de pré-preparo
          if (this.p.prd_tipo === 2) {
            this.campo('pro_Material', 'mat_ID', '(mat_ID = ' + this.novoMaterial.value + ') AND (prd_ID > 0)').then(
              (c) => {
                this.$refs['modal-add'].hide()
                this.$swal('Desculpe, não é possível inserir um preparo dentro de outro preparo')
                this.novoMaterial = []
                this.recarregar(true)
              },
              (c) => {
                var elem = {
                  prd_ID: this.produto, 
                  pmt_mat_ID: this.novoMaterial.value, 
                  pmt_active: 1, 
                  pmt_qtde: 1,
                  grp_ID: this.$session.get('grupo')
                }
                this.criar(elem)
              }
            )
          } else {
            var elem = {
              prd_ID: this.produto, 
              pmt_mat_ID: this.novoMaterial.value, 
              pmt_active: 1, 
              pmt_qtde: 1,
              grp_ID: this.$session.get('grupo')
            }
            this.criar(elem)
          }
        }
      )
    },
    criar: function (el) {
      this.$http.post(this.URLApi + 'prolucro/_table/pro_Produto_Material', {resource: [ el ]}, {
        headers: {
          'X-DreamFactory-Session-Token': this.$session.get('jwt'),
          'X-Dreamfactory-API-Key': this.ChaveApi
        }
      }).then(
        (response) => {
          this.mat = []
          this.mat1 = []
          this.mat2 = []
          this.mat3 = []
          this.mat4 = []
          this.$refs['modal-add'].hide()
          this.recarregar(true)
          this.novoMaterial = []
        },
        (response) => {
          this.$swal('Falha ao inserir')
          this.novoMaterial = []
        }
      )
    },
    editar: function (id, g, ind) {
      this.loading = true
      var index = 0
      // Procura o index do id desejado
      for (var i = 0; i < this.mat.length; i++) {
        if (this.mat[i].pmt_ID === id) {
          index = i
          break
        }
      }
      // busca os mats de acordo com o grupo
      var qtde = 0
      var active = 0
      if (g === 1) {
        qtde = this.mat1[ind].pmt_qtde
        active = this.mat1[ind].pmt_active
      }
      if (g === 2) {
        qtde = this.mat2[ind].pmt_qtde
        active = this.mat2[ind].pmt_active
      }
      if (g === 3) {
        qtde = this.mat3[ind].pmt_qtde
        active = this.mat3[ind].pmt_active
      }
      if (g === 4) {
        qtde = this.mat4[ind].pmt_qtde
        active = this.mat4[ind].pmt_active
      }
      if (qtde <= 0) {
        this.$swal('A quantidade precisa ser maior do que zero.')
        this.loading = false
        return false
      }
      this.atualizar('pro_Produto_Material', {resource: [ {prd_ID: this.produto, pmt_qtde: qtde, pmt_active: active} ]}, 'pmt_ID = ' + id).then(
        (response) => {
          if (!active) {
            this.remover('pro_Produto_Material', 'pmt_ID = ' + id)
          }
          this.recarregar(false)
        },
        (response) => {
          this.$swal('Erro ao atualizar: ' + JSON.stringify(response))
          this.loading = false
        }
      )
    },
    voltar: function (x) {
      if (x) {
        this.$router.push("/configuracoes/" + this.tipoProdutoSimples + "s")
      } else {
        this.$router.push("/precificador")
      }
    },
    showModalSimples: function (id) {
      this.$refs['modal-simples'].show()
    },
    calcSimplesData: function () {
      var s = 0
      var tot = 0
      var d = this.dataTable
      if (d.length > 0) {
        for (var i = 0; i < d.length; i++) {
          if (typeof d[i][0] !== 'undefined' && d[i][0] !== null && d[i][0].length > 0) {
            // Multiplica a coluna 1 com a 2
            if (parseFloat(d[i][1]) > 0 && parseFloat(d[i][2]) > 0)
            {
              d[i][3] = parseFloat(d[i][1] * d[i][2])
              s += parseFloat(d[i][3])
            }
          }
        }
      }
      // var s = this.$refs.hotTableComponent.hotInstance.getDataAtCol(3)
      this.dataTable = d
      this.totalDataTable = s
      if (typeof this.$refs['dataTables'] !== 'undefined') {
        this.$refs['dataTables'].hotInstance.render()
      }
    },
    salvaSimplesData: function () {
      this.atualizar('pro_Produto_Material', {resource: [{ pmt_ID: this.pmt_fixo, pmt_qtde: this.totalDataTable }]}, 'pmt_ID = ' + this.pmt_fixo)
      this.campo('gp_Metadados', 'id', '(string LIKE insumos_' + this.produto + ') AND (module LIKE insumos)').then(
        (response) => {
          if (typeof response !== 'undefined' && response > 0) {
            // Ja existe. Atualiza.
            this.atualizar('gp_Metadados', {resource: [ {string: 'insumos_' + this.produto, module: 'insumos', description: JSON.stringify(this.dataTable)} ]}, 'id = ' + response, '', '1')
            this.$refs['modal-simples'].hide()
            this.recarregar(true)
          } else {
            // Nao existe. Cria
            this.inserir('gp_Metadados', {resource: [ {string: 'insumos_' + this.produto, module: 'insumos', description: JSON.stringify(this.dataTable)} ]}, '', '1')
            this.$refs['modal-simples'].hide()
            this.recarregar(true)
          }
        }, 
        (response) => {
          // Nao existe. Cria.
          this.inserir('gp_Metadados', {resource: [ {string: 'insumos_' + this.produto, module: 'insumos', description: JSON.stringify(this.dataTable)} ]}, '', '1')
          this.$refs['modal-simples'].hide()
          this.recarregar(true)
        }
      )
    },
    carregaSimplesData: function () {
      this.campo('gp_Metadados', 'description', '(string LIKE insumos_' + this.produto + ') AND (module LIKE insumos)').then(
        (k) => {
          this.dataTable = JSON.parse(k)
          this.calcSimplesData()
        },
        (k) => {
          this.dataTable = []
        }
      )
      // Verifica se é um produto de preparo e atualiza o valor
      this.campos('pro_Material', '(prd_ID = ' + this.produto + ')').then(
        (x) => {
          if (x && x.mat_ID > 0) {
            // Pega o total de material em uso
            // this.campo('filtro_Produto', 'prd_custodireto_total', '(prd_ID = ' + this.produto + ')').then(
            //  (r) => {
                // Atualiza no pro_Material
                // this.atualizar('pro_Material', {resource: [ {mat_total: r, mat_value: r} ]}, '(mat_ID = ' + x + ')', '', '1')
            //  },
            //  (r) => {}
            // )
            var r = (parseFloat(x.mat_custominuto || 0) + parseFloat(this.produto_total / this.unidadeVenda))
            this.atualizar('pro_Material', {resource: [ {mat_custohora: parseFloat(this.produto_total / this.unidadeVenda), mat_total: r, mat_value: r} ]}, '(mat_ID = ' + x.mat_ID + ')', '', '1')
          }
        },
        (x) => {}
      )
      this.atualizar('pro_Produto', {resource: [ {prd_subtotal: parseFloat(this.produto_total / this.unidadeVenda)} ]}, '(prd_ID = ' + this.produto + ')', '', '1')
    },
    removeSimplesData: function () {
      this.dataTable = []
      this.atualizar('pro_Produto_Material', {resource: [{ pmt_ID: this.pmt_fixo, pmt_qtde: 0 }]}, 'pmt_ID = ' + this.pmt_fixo)
      this.campo('gp_Metadados', 'id', '(string LIKE insumos_' + this.produto + ') AND (module LIKE insumos)').then(
        (response) => {
          if (typeof response !== 'undefined' && response > 0) {
            // Ja existe. Atualiza.
            this.atualizar('gp_Metadados', {resource: [ {string: 'insumos_' + this.produto, module: 'insumos', description: '[]'} ]}, 'id = ' + response, '', '1')
            this.recarregar(true)
          } else {
            // Nao existe. Cria
            this.inserir('gp_Metadados', {resource: [ {string: 'insumos_' + this.produto, module: 'insumos', description: '[]'} ]}, '', '1')
            this.recarregar(true)
          }
        }, 
        (response) => {
          // Nao existe. Cria.
          this.inserir('gp_Metadados', {resource: [ {string: 'insumos_' + this.produto, module: 'insumos', description: '[]'} ]}, '', '1')
          this.recarregar(true)
        }
      )
    },
    ajuda: function() {
      this.$bvModal.show('ajuda')
    }
  },
  components: {
    Loading,
    VueAutonumeric,
    HotTable
  }
}
</script>